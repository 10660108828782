/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components'
import {
  Heading,
  Flex,
  Text,
  CommunityIcon,
  Box
} from '@pancakeswap/uikit'
import useTokenPrice from 'hooks/useStaticInfo'
import useTheme from 'hooks/useTheme'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { IconCardData } from '../IconCard'
import ExchangeStatics from './ExchangeStatics';


const AdvantagesCard = styled.li`
  border: 1px solid;
  border-image-source: radial-gradient(100% 100% at 50% 0, rgba(112, 88, 255, .58) 0, rgba(183, 171, 255, 0) 39.78%, hsla(0, 0%, 100%, 0) 80.46%, hsla(0, 0%, 100%, .13) 100%);
  background: ${({ theme }) => theme.colors.backgroundAlt2};
  padding: 1rem;
  border-radius: 1rem;
  display: grid;
  grid-auto-rows: auto;
  gap: 1rem;

  @media (min-width: 640px) {
    padding: 1.5rem;
    gap: 2rem;
  }

  @media (min-width: 768px) {
    padding: 2.25rem;
  }
`;

const advantagesData = [
  {
    title: 'Swap tokens',
    description: 'Trade any Binance chain (BEP-20) tokens on the platform with ease & fast, Low fees and secure.',
    imgSrc: '/images/swap.png',
    imgAlt: 'swap tokens',
    imgWidth: 166,
    imgHeight: 200,
    imgHeightClass: 'h-[138px]',
  },
  {
    title: 'Liquidity Pools',
    description: 'After Connect.Your manage liquidity pools such as add liquidity or withdraw easily & securely.',
    imgSrc: '/images/pools.png',
    imgAlt: 'liquidity pools',
    imgWidth: 167,
    imgHeight: 200,
    imgHeightClass: 'h-[130px]',
  },
  {
    title: 'Earn Rewards',
    description: 'Create passive income. It’s time to enjoy your life and let your money work for you every day.',
    imgSrc: 'images/staking.png',
    imgAlt: 'earn rewards',
    imgWidth: 260,
    imgHeight: 205,
    imgHeightClass: 'h-[113px]',
  },
  {
    title: 'Fast Transaction',
    description: 'Fast transaction supports multiple transactions simultaneously and is stable without errors.',
    imgSrc: 'images/fast.png',
    imgAlt: 'earn rewards',
    imgWidth: 260,
    imgHeight: 205,
    imgHeightClass: 'h-[113px]',
  },
  {
    title: 'Safe & Secure',
    description: 'The system is highly secured, using Pancakeswap as a gateway for real-time data connectivity.',
    imgSrc: 'images/secure.png',
    imgAlt: 'earn rewards',
    imgWidth: 260,
    imgHeight: 205,
    imgHeightClass: 'h-[113px]',
  },
  {
    title: 'Low Fees',
    description: 'On our platform we support Binance chain, We have set lower fees than most other exchanges.',
    imgSrc: 'images/lowfee.png',
    imgAlt: 'earn rewards',
    imgWidth: 260,
    imgHeight: 205,
    imgHeightClass: 'h-[113px]',
  }
];

const walletsData = [
  {
    src: '/images/binance.png',
    alt: 'Binance Labs',
    width: 162,
    height: 40,
    imgHeightClass: 'h-[29px] sm:h-[40px]',
  },
  {
    src: '/images/bitget.png',
    alt: 'Laughing capital',
    width: 162,
    height: 42,
    imgHeightClass: 'h-[33px] sm:h-[42px]',
  },
  {
    src: '/images/coinbase.png',
    alt: 'Kronos',
    width: 128,
    height: 54,
    imgHeightClass: 'h-[43px] sm:h-[54px]',
  },
  {
    src: '/images/exodus.png',
    alt: 'Token Pocket',
    width: 62,
    height: 42,
    imgHeightClass: 'h-[31px] sm:h-[40px]',
  },
  {
    src: '/images/mathwallet.png',
    alt: 'Lux Capital',
    width: 222,
    height: 36,
    imgHeightClass: 'h-[36px] sm:h-[50px]',
  },
  {
    src: '/images/metamask.png',
    alt: 'Safepal',
    width: 165,
    height: 45,
    imgHeightClass: 'h-[45px] sm:h-[57px]',
  },
  {
    src: '/images/safepall.png',
    alt: '3commas',
    width: 192,
    height: 52,
    imgHeightClass: 'h-[31px] sm:h-[52px]',
  },
  {
    src: '/images/trust-wallet.png',
    alt: '3commas',
    width: 192,
    height: 52,
    imgHeightClass: 'h-[31px] sm:h-[52px]',
  },
];

const ecoSystemData = [
  {
    href: "https://richpst.com/staking/",
    imgSrc: "/images/pic1.png",
    imgAlt: "Web3 Staking Image",
    imgClassName: "h-20 w-auto sm:h-[140px]",
    svgColor: "#4347FF",
    title: "Web3 Staking",
    description:
      "Staking created a passive income generation technology. Bonus automatically deposited in your wallet.",
  },
  {
    href: "https://pstmarket.com/",
    imgSrc: "/images/pic2.png",
    imgAlt: "Payment",
    imgClassName: "h-20 w-auto sm:h-[140px]",
    svgColor: "#D728DA",
    title: "Payment",
    description:
      "Use PSTCoin to pay products & services on the marketplace of our projects and partners worldwide.",
  },
  {
    href: "https://pstswap.com/",
    imgSrc: "/images/pic3.png",
    imgAlt: "Marketplace Image",
    imgClassName: "h-20 w-auto sm:h-[140px]",
    svgColor: "#19477D",
    title: "Marketplace",
    description:
      "We have collected more than +10K products from manufacturers low prices and have a vendor system.",
  }
];

const AdvantagesCard2 = styled.li`
  border: 0.5px solid #263B61;
  background: ${({ theme }) => theme.colors.backgroundAlt2};
  border-radius: 20px;
`;

const Stats = () => {
  const { theme } = useTheme()
  const { observerRef } = useIntersectionObserver();
  const priceStats = useTokenPrice()


  const UsersCardData: IconCardData = {
    icon: <CommunityIcon color="primary" width="72px" />,
  }


  const WalletListItem = ({ src, alt, width, height, imgHeightClass }) => (
    <li className="flex min-w-[calc(50%-0.25rem)] sm:min-w-[calc(33%-2rem)] md:min-w-[calc(25%-3rem)] max-w-[calc(50%-0.25rem)] sm:max-w-[calc(33%-2rem)] md:max-w-[calc(25%-3rem)] h-[77px] sm:min-h-[112px] rounded-lg justify-center items-center border border-solid border-[rgba(255,255,255,0.09)] bg-[rgba(255,255,255,0.02)]">
      <img
        loading="lazy"
        src={src}
        alt={alt}
        width={width}
        height={height}
        decoding="async"
        className={`w-auto ${imgHeightClass}`}
        sizes="(max-width: 767px) 480px, 800px"
      />
    </li>
  );

  return (
    <>

      <Flex mb="48px" flexWrap="wrap" style={{ textAlign: "center" }} justifyContent="space-around">
        <Box width={["100%", null, "23%"]}>
          <ExchangeStatics
            headingText={`$${new Intl.NumberFormat('en-US', { maximumSignificantDigits: 4 }).format(priceStats.tokenprice)}`}
            bodyText="PST Price"
            highlightColor={theme.colors.primary}
            {...UsersCardData}
          />
        </Box>
        <Box width={["100%", null, "23%"]}>
          <ExchangeStatics
            headingText={`$${new Intl.NumberFormat('en-US', { maximumSignificantDigits: 9 }).format(priceStats.Marketcap)}`}
            bodyText="Marketcap"
            highlightColor={theme.colors.primary}
            {...UsersCardData}
          />
        </Box>
        <Box width={["100%", null, "23%"]}>
          <ExchangeStatics
            headingText={`${new Intl.NumberFormat('en-US', { maximumSignificantDigits: 9 }).format(Number(priceStats.totalTx.toString()))}`}
            bodyText="Total Transactions"
            highlightColor={theme.colors.primary}
            {...UsersCardData}
          />
        </Box>
        <Box width={["100%", null, "23%"]}>
          <ExchangeStatics
            headingText={`${new Intl.NumberFormat('en-US', { maximumSignificantDigits: 9 }).format(Number(priceStats.totalPair.toString()))}`}
            bodyText="Total Trading Pairs"
            highlightColor={theme.colors.primary}
            {...UsersCardData}
          />
        </Box>

      </Flex>

      <Flex mt="135px" mb="25px" justifyContent="center" alignItems="center" flexDirection="column" ref={observerRef}>
        <Heading mb="10px" color='primary' textAlign="center" scale="xl">
          Advantages
        </Heading>
        <Text textAlign="center" color='textSubtle'>
          Empowering traders and stakers with unparalleled opportunities.
        </Text>
      </Flex>

      <ul className="grid sm:grid-cols-2 md:sm:grid-cols-3 auto-rows-min gap-3 sm:gap-4 md:gap-6 max-w-[1200px] mx-auto mt-3 sm:mt-8">
        {advantagesData.map((advantage, index) => (
          <AdvantagesCard>
            <div>
              <Text className="text-subtitle1 sm:text-headline3 text-t-primary font-bold text-center" fontSize="20px">
                {advantage.title}
              </Text>
              <Text color="textSubtle" className="mt-2 sm:mt-3 text-t-third text-body2 sm:text-body1">
                {advantage.description}
              </Text>
            </div>
            <div className="flex md:items-end justify-center">
              <img
                alt={advantage.imgAlt}
                loading="lazy"
                width={advantage.imgWidth}
                height={advantage.imgHeight}
                decoding="async"
                data-nimg="1"
                className={`w-auto ${advantage.imgHeightClass} sm:h-auto`}
                src={advantage.imgSrc}
                style={{ color: 'transparent' }}
              />
            </div>
          </AdvantagesCard>
        ))}
      </ul>

      <div className="mt-[5.5rem] sm:mt-32 md:mt-[11.25rem] max-w-[1200px] mx-auto px-4 sm:px-6">

        <Heading mb="10px" color='primary' textAlign="center" scale="xl">
          Wallet integrate
        </Heading>

        <Text textAlign="center" color='textSubtle'>
          We value the safety of everyone&apos;s funds. Supports the use of Binance smart chain wallet.
        </Text>

        <ul className="flex gap-2 sm:gap-4 mt-6 sm:mt-7 md:mt-10 flex-wrap justify-center">
          {walletsData.map((image, index) => (
            <WalletListItem key={Math.random()} {...image} />
          ))}
        </ul>
      </div>


      <Flex mt="135px" mb="25px" justifyContent="center" alignItems="center" flexDirection="column" ref={observerRef}>
        <Heading color='primary' mb="10px" textAlign="center" scale="xl">
          Ecosystem
        </Heading>
      </Flex>

      <ul className="mt-[3.125rem] grid grid-rows-[minmax(86px,1fr)] md:grid-cols-3 gap-7 max-w-[1200px] mx-auto">
        {ecoSystemData.map((item, index) => (
          <AdvantagesCard2
            key={Math.random()}

            className="relative p-6 isolate grid grid-cols-[minmax(0,124px)_1fr] gap-4 md:gap-0 md:grid-cols-1 md:auto-rows-min overflow-hidden md:overflow-visible"
          >
            <div className="relative flex justify-center md:static">
              <svg
                className="max-w-full h-[86px] sm:h-[112px] absolute -z-[5] top-[20px] sm:top-[47px] sm:translate-y-0"
                viewBox="0 0 390 112"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_514_1560)">
                  <g opacity="0.4" filter="url(#filter0_f_514_1560)">
                    <ellipse
                      cx="195.5"
                      cy="0.5"
                      rx="154.5"
                      ry="49.5"
                      fillOpacity="0.42"
                    />
                  </g>
                </g>
                <defs>
                  <filter
                    id="filter0_f_514_1560"
                    x="-19"
                    y="-109"
                    width="429"
                    height="219"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feGaussianBlur
                      stdDeviation="30"
                      result="effect1_foregroundBlur_514_1560"
                    />
                  </filter>
                  <clipPath id="clip0_514_1560">
                    <rect width="390" height="112" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <img
                alt={item.title}
                aria-hidden="true"
                loading="lazy"
                width="120"
                height="140"
                decoding="async"
                data-nimg="1"
                className="h-20 w-auto sm:h-[140px]"
                src={item.imgSrc}
              />
            </div>
            <div className="sm:p-8 md:p-0">
              <h3 className="md:text-center text-t-primary text-subtitle2 sm:text-subtitle4 md:text-headline3 md:font-medium md:mt-6" style={{ fontSize: "20px" }}>
                {item.title}
              </h3>
              <Text color='textSubtle' className="text-t-third md:text-center text-body2 sm:text-body1 [text-wrap:_balance] mt-0.5 sm:mt-2 md:mt-3">
                {item.description}
              </Text>
            </div>
          </AdvantagesCard2>
        ))}
      </ul>
    </>
  )
}

export default Stats